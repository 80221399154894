import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  infoContainer: {
    padding: theme.spacing(2),
    backgroundColor: '#e7f0ff', // Light blue background color
    marginBottom: theme.spacing(2),
    width: '80%',
    maxWidth: '1000px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    margin: 'auto',
    marginTop: theme.spacing(4),
  }
}));

const InfoSection = () => {
  const classes = useStyles();

  return (
      <Paper className={`${classes.infoContainer}`}>
        <Typography variant="h6">How the Buylist Attacher Works:</Typography>
        <Typography variant="body1">
          You fill out the customers information similar to how they would if they were completing a buylist themselves.
        </Typography>
        <Typography variant="body1">
          You must enter in an email that already has an MTG MATE account.
        </Typography>
        <Typography variant="body1">
          The CSV at the bottom can be in 2 formats: ? format and Roca Generated Format.
        </Typography>

        <Typography variant="body1">
          The buylist attacher will do the following:
        </Typography>
        <ul>
          <li>it will use the CSV to add cards to a buylist</li>
          <li>it will <strong>NOT</strong> add overstock, it will simply skip it.</li>
          <li>if a buylist exceeds 300 cards, it will submit it, and start creating a new one.</li>
        </ul>

        <Typography variant="body1">
          These buylists will be attached the users account, and they will be able to view and see them as per normal.
        </Typography>

        <Typography variant="h6">Supported CSV Formats</Typography>

        <Typography variant="body1">
          <strong>Regular format</strong> (Not exactly sure where this format comes from, it's just the last one I integrated with. Subject to change):
        </Typography>
        <pre className="alert alert-light mt-1 text-dark">Name,Edition,Quantity,"Collector's number","Foil/Etched"</pre>
        <Typography variant="body1">
          Please note, this import <strong>does not</strong> support importing foil and etched cards at the moment.
        </Typography>

        <Typography variant="body1">
          <strong>ROCA:</strong>
        </Typography>
        <pre className="alert alert-light mt-1 text-dark">"Add Qty"," Scryfall ID", " Finish"</pre>
        <Typography variant="body1">
          Please note: ROCA formats " Scryfall ID" and " Finish" with a space in front of it
        </Typography>
        <Typography variant="body1">
          This format does support importing Foil cards.
        </Typography>
      </Paper>
  );
};

export default InfoSection;
